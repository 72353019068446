import React from 'react';
import { NavLink } from 'react-router-dom';
import './Navbar.css'; // Import the CSS styles

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-square-1"></div>
      <div className="navbar-left">        
        <NavLink exact="true" to="/" className="nav-link" activeclassname="active">
          НовыйУрок
        </NavLink>
      </div>
      <div className="navbar-center">        
        <NavLink to="/lessons" className="nav-link" activeclassname="active">
          Материалы
        </NavLink>
        <NavLink to="/about" className="nav-link" activeclassname="active">
          О нас
        </NavLink>
      </div>
      <div className="navbar-right">
        <NavLink to="/user-cabinet" className="nav-link" activeclassname="active">
          Личный кабинет
        </NavLink>
        <div className="navbar-square-2"></div>
      </div>
    </nav>
  );
}

export default Navbar;